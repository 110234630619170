// extracted by mini-css-extract-plugin
export var colorLight = "layout-module--color-light--1iJBP";
export var colorLightgray = "layout-module--color-lightgray--2NrOd";
export var colorDark = "layout-module--color-dark--2RRmt";
export var colorBlack = "layout-module--color-black--1AFO8";
export var colorHacker = "layout-module--color-hacker--1PCQc";
export var colorCobalt = "layout-module--color-cobalt--2ZUXM";
export var colorSepia = "layout-module--color-sepia--2l7JM";
export var colorLowcontrast = "layout-module--color-lowcontrast--2npyc";
export var layoutContainer = "layout-module--layoutContainer--3vHGM";
export var headerBar = "layout-module--headerBar--1H9f_";
export var contentContainer = "layout-module--contentContainer--2-US4";
export var logoBox = "layout-module--logoBox--1K2s7";
export var general = "layout-module--general--O1R5I";
export var menuOpen = "layout-module--menuOpen--1L6X_";
export var menuCover = "layout-module--menuCover--1u0UM";
export var menuCoverContent = "layout-module--menuCoverContent--33XlT";
export var menuCloseButton = "layout-module--menuCloseButton--35yFc";
export var title = "layout-module--title--1ZPyO";
export var menuOpenButton = "layout-module--menuOpenButton--2-sUL";
export var coverImage = "layout-module--coverImage--3dgb8";
export var dropIn = "layout-module--dropIn--ugW8_";